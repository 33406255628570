import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ContactsData } from '@bazis/form/models/form-element.types';
import { PhonePipe } from '@bazis/shared/pipes/phone.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-card-contact',
    templateUrl: './card-contact.component.html',
    standalone: true,
    imports: [PhonePipe, TranslocoModule, ColorDirective, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardContactComponent {
    @Input() contact: ContactsData;

    @HostBinding('class') hostClassComponent = 'bazis-contact';

    constructor() {}
}
