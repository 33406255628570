import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-dummy-mobile',
    templateUrl: './dummy-mobile.component.html',
    standalone: true,
    imports: [TranslocoModule, ColorDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DummyMobileComponent {}
