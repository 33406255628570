import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Output,
    ViewContainerRef,
} from '@angular/core';

@Directive({
    selector: '[drop-zone]',
    standalone: true,
})
export class DropZoneDirective {
    @Output()
    filesDropped = new EventEmitter<Array<File>>();

    @HostBinding('class.drop-zone-active')
    active = false;

    @HostListener('drop', ['$event'])
    onDrop(e: DragEvent) {
        e.preventDefault();
        this.active = false;

        const { dataTransfer } = e;
        if (dataTransfer.items) {
            const files = [];
            for (let i = 0; i < dataTransfer.items.length; i++) {
                if (dataTransfer.items[i].kind === 'file') {
                    files.push(dataTransfer.items[i].getAsFile());
                }
            }
            dataTransfer.items.clear();
            this.filesDropped.emit(files);
        } else {
            const files = dataTransfer.files;
            dataTransfer.clearData();
            this.filesDropped.emit(Array.from(files));
        }
    }

    @HostListener('dragover', ['$event'])
    onDragOver(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        this.active = true;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(e: DragEvent) {
        this.active = false;
    }

    @HostListener('body:dragover', ['$event'])
    onBodyDragOver(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    @HostListener('body:drop', ['$event'])
    onBodyDrop(e: DragEvent) {
        e.preventDefault();
    }

    constructor(public viewContainerRef: ViewContainerRef) {}
}
