import { Pipe, PipeTransform } from '@angular/core';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisConfigurationService } from '@bazis/configuration.service';

@Pipe({
    name: 'enums',
    standalone: true,
})
export class EnumsPipe implements PipeTransform {
    constructor(private entity: BazisEntityService, private config: BazisConfigurationService) {}

    transform(enumValues: string[], type: string, property: string, delimiter = ', ') {
        type = type.replace('.', '__');
        const attribute = this.config.schemas[`${type}__schema_retrieve`].attributes[property];
        return enumValues?.length
            ? enumValues.map((enumValue) => attribute.enumDict[enumValue]).join(delimiter)
            : null;
    }
}
