import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BazisContextMenuService } from '@bazis/shared/services/context-menu.service';
import { ContextMenuComponent } from '@bazis/shared/components/context-menu/context-menu.component';
import { CommonModule } from '@angular/common';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { ContextMenuDirective } from '@bazis/shared/directives/context-menu.directive';

@Component({
    selector: 'bazis-context-menus',
    templateUrl: './context-menus.component.html',
    standalone: true,
    imports: [ContextMenuComponent, CommonModule, ColorDirective, ContextMenuDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenusComponent implements OnDestroy, OnInit {
    constructor(public contextMenuService: BazisContextMenuService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
