<ng-container *transloco="let t">
    <div
        *ngIf="list$ | async as breadcrumbs"
        class="bazis-container bazis-breadcrumbs"
    >
        <div
            *ngIf="breadcrumbs.length > doNotDisplayLength"
            class="bazis-list bazis-list_h"
            itemscope
            itemtype="https://schema.org/BreadcrumbList"
            [class.bh-flex-wrap]="mqSrv.inIntervalBreakpoint('xs', 'lg')"
        >
            <div
                *ngFor="let breadcrumb of breadcrumbs; let i = index"
                class="bazis-item"
                [class.bazis-only-icon]="breadcrumb.useOnlyIcon"
                itemprop="itemListElement"
                itemscope
                itemtype="https://schema.org/ListItem"
            >
                <bazis-icon
                    *ngIf="i !== 0"
                    [icon]="iconSeparator"
                    size="s"
                ></bazis-icon>
                <!-- move to breadcrumbs item component if we will have more different types -->
                <a
                    *ngIf="!breadcrumb.name && breadcrumb.type === 'entityData'"
                    [routerLink]="breadcrumb.url"
                    itemprop="item"
                >
                    <bazis-icon
                        *ngIf="breadcrumb.icon"
                        [icon]="breadcrumb.icon"
                    ></bazis-icon>
                    <span
                        *ngIf="!breadcrumb.useOnlyIcon"
                        itemprop="name"
                    >
                        <ng-container
                            *ngIf="
                                breadcrumb.entity.entityType !== 'organization.organization_info'
                            "
                        >
                            <!-- if breadcrumb with entity type is last in a chain, suppose that this entity will be asked in main component -->
                            <ng-container
                                *ngIf="
                                    breadcrumb.params[breadcrumb.entity.idParamName || 'id']
                                        | entity
                                            : breadcrumb.entity.entityType
                                            : false
                                            : i === breadcrumbs.length - 1
                                        | async as entity
                                "
                            >
                                {{ entity.$snapshot[breadcrumb.entity.nameField || 'name'] }}
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *ngIf="
                                breadcrumb.entity.entityType === 'organization.organization_info'
                            "
                        >
                            <ng-container
                                *ngIf="
                                    breadcrumb.params[breadcrumb.entity.idParamName || 'id']
                                        | organization
                                        | async as entity
                                "
                                >{{ entity.$snapshot[breadcrumb.entity.nameField || 'name'] }}
                            </ng-container>
                        </ng-container>
                    </span>
                </a>
                <a
                    *ngIf="breadcrumb.name"
                    [routerLink]="breadcrumb.url"
                    itemprop="item"
                >
                    <bazis-icon
                        *ngIf="breadcrumb.icon"
                        [icon]="breadcrumb.icon"
                    ></bazis-icon>
                    <span
                        *ngIf="!breadcrumb.useOnlyIcon"
                        itemprop="name"
                    >
                        {{ t(breadcrumb.name, breadcrumb.params) }}
                    </span>
                </a>
                <meta
                    itemprop="position"
                    [content]="i + 1"
                />
            </div>
        </div>
    </div>
</ng-container>
