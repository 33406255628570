import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DOCUMENT_LINKS } from '@bazis/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-cookie',
    templateUrl: './cookie.component.html',
    standalone: true,
    imports: [ButtonModule, IconModule, CommonModule, TranslocoModule, ColorDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieComponent implements OnInit {
    @Input() link = DOCUMENT_LINKS.cookiePolicy;

    showCookie = false;

    constructor(private cookieService: CookieService) {}

    ngOnInit(): void {
        this.calculateCookie();
    }

    accept() {
        this.cookieService.set('cookieAccepted', 'true');
        this.calculateCookie();
    }

    calculateCookie() {
        this.showCookie = !this.cookieService.get('cookieAccepted');
    }
}
