import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ContextMenuDirective } from '@bazis/shared/directives/context-menu.directive';
import { BazisContextMenuService } from '@bazis/shared/services/context-menu.service';
import { CommonModule } from '@angular/common';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-context-menu',
    templateUrl: './context-menu.component.html',
    standalone: true,
    imports: [CommonModule, ColorDirective, ContextMenuDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent implements OnInit, OnDestroy {
    @Input()
    contextMenu: any;

    @ViewChild(ContextMenuDirective, { static: true }) componentHost!: ContextMenuDirective;

    @HostListener('document:keydown.escape') onKeydownHandler() {
        if (this.contextMenu.options.keyboardClose) {
            this.close();
        }
    }

    componentRef: ComponentRef<any>;

    constructor(public contextMenuService: BazisContextMenuService) {}

    ngOnInit(): void {
        this.loadComponent();
    }

    ngOnDestroy(): void {}

    close() {
        if (this.componentRef.instance.close) {
            this.componentRef.instance.close();
        } else {
            this.contextMenuService.dismiss(null, this.contextMenu);
        }
    }

    loadComponent() {
        const component = this.contextMenu.options.component;
        const viewContainerRef = this.componentHost.viewContainerRef;
        viewContainerRef.clear();
        this.componentRef = viewContainerRef.createComponent(component);
        if (this.contextMenu.options.componentProperties) {
            Object.keys(this.contextMenu.options.componentProperties).forEach((key) => {
                this.componentRef.instance[key] = this.contextMenu.options.componentProperties[key];
            });
        }
    }
}
