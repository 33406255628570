import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BazisBreadcrumbsService } from '@bazis/shared/services/breadcrumbs.service';
import { BazisLoaderService } from '@bazis/shared/services/loader.service';
import { environment } from '@environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, of } from 'rxjs';
import { MpAuthService } from '@shared/services/auth.service';
import { AppService } from '@app/app.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    assembly = environment;

    constructor(
        public authService: MpAuthService,
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbs: BazisBreadcrumbsService,
        private loaderService: BazisLoaderService,
        private swUpdate: SwUpdate,
        public deviceService: DeviceDetectorService,
        public appService: AppService,
    ) {
        const loader = this.loaderService.create({});
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                switchMap((event) => combineLatest([of(event), this.authService.role$])),
                tap(([route, role]) => {
                    this.breadcrumbs.createBreadcrumbs(this.activatedRoute.root, role);
                    this.loaderService.dismiss(loader);
                }),
            )
            .subscribe();

        if (this.swUpdate.isEnabled) {
            this.swUpdate.checkForUpdate().then((hasVersion) => {
                if (hasVersion) window.location.reload();
            });
        }
    }

    setDocTitle() {
        this.titleService.setTitle('Fin Platform');
    }
}
