import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { ToolbarModule } from '@bazis/shared/components/web/toolbar/toolbar.module';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-simple-modal',
    templateUrl: './simple-modal.component.html',
    standalone: true,
    imports: [CommonModule, ButtonModule, ToolbarModule, ColorDirective, TranslocoModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleModalComponent implements OnInit, OnDestroy {
    @Input()
    message = '';

    @Input()
    titleKey = '';

    constructor(private modalService: BazisModalService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    closeModal() {
        this.modalService.dismiss();
    }
}
