import { ViewportScroller } from '@angular/common';
import { Directive, Input, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[anchor]',
    standalone: true,
})
export class AnchorDirective {
    constructor(private viewportScroller: ViewportScroller) {}

    scrollToAnchor(anchor: string) {
        this.viewportScroller.scrollToAnchor(anchor);
    }

    @Input() anchor: string;

    @HostBinding('class') get elementClass() {
        return {
            ['bh-pointer']: true,
        };
    }

    @HostListener('click', ['$event.target'])
    public onClick(targetElement) {
        this.scrollToAnchor(this.anchor);
    }
}
