import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SliderComponent } from '@bazis/shared/components/slider/slider.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { SwipeDirective } from '@bazis/shared/directives/swipe.directive';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-gallery-slider',
    templateUrl: './gallery-slider.component.html',
    standalone: true,
    imports: [ColorDirective, CommonModule, ButtonModule, IconModule, SwipeDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySliderComponent extends SliderComponent {}
