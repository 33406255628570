import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { SkeletonModule } from '@bazis/shared/components/web/skeleton/skeleton.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-gallery-skeleton',
    templateUrl: './gallery-skeleton.component.html',
    standalone: true,
    imports: [ColorDirective, NgClass, SkeletonModule, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySkeletonComponent implements OnInit {
    @Input() isPreview: boolean;

    @Input() cssClass: string;

    constructor() {}

    ngOnInit() {}
}
