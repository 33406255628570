import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BazisAlertService } from '@bazis/shared/services/alert.service';
import { SignStatusModule } from '@bazis/shared/components/web/sign-status/sign-status.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
    selector: 'bazis-alert',
    templateUrl: './alert.component.html',
    standalone: true,
    imports: [
        IconModule,
        ButtonModule,
        SignStatusModule,
        ColorDirective,
        CommonModule,
        TranslocoModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnDestroy, OnInit {
    @HostListener('document:keydown.escape') onKeydownHandler() {
        this.dismiss();
    }

    constructor(public alertService: BazisAlertService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    buttonClick(button) {
        this.dismiss(button.action);
    }

    dismiss(action = null) {
        this.alertService.dismiss(action);
    }
}
