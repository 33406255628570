import { Observable } from 'rxjs';

export type SrvResponse = {
    data: SrvEntData[] | SrvEntData;
    meta: any;
    included: any[];
};

export type SimpleData = {
    id: string;
    [key: string]: any;
};

export type EntDescription = {
    id: string;
    type: string;
};

export type EntData = {
    type: string;
    id?: string;
    included?: EntData[];
    $snapshot?: any;
    $meta?: {
        state_actions: any[];
    };
    $transitMap?: {
        [index: string]: EntTransit;
    };
    $transits?: string[];
    $canSign?: boolean;
    $signContext?: string;
    $canAdd?: boolean;
    $canChange?: boolean;
    $canView?: boolean;
    $canDelete?: boolean;
    $timestamp?: number;
};

export type EntList = {
    type?: string;
    list: EntData[];
    $meta?: {
        pagination: any;
        status_allowed?: string[];
    };
};

export type EntTransit = {
    id: string;
    isAllowed: boolean;
    restricts: any[];
    payload: { [index: string]: any };
    hint: string;
    url: string;
    needMySign: boolean;
    needOtherSign: boolean;
    showSignButtonInsteadOfTransit: boolean;
    restrictsToDisplay: any[];
};

export type EntTransitData = {
    transit: string;
    payload: string;
};

export type EntDocumentSettings = {
    entityType: string;
    entityId: string;
    contextLabel?: string;
    documentPayload?: any;
    signBodyPayload?: any;
    document?: EntData;
    cancelPreviousSignings?: boolean;
    withoutIncludedDocuments?: boolean;
};

export type ItemToSign = EntDocumentSettings & {
    document$: Observable<EntData>;
};

export type EntSchema = {
    entityType: string;
    id: string;
    attributes: any;
    relationships: any;
    required: string[];
    error?: number;
    schemaType?: SchemaType;
    included?: EntSchema[];
};

export type EntPriority = {
    id: string;
    property: string;
    value?: boolean;
    defaultVisibility?: boolean;
    isVisibleFn?: (filtersValue: any, value: any) => boolean;
};

export type SchemaType =
    | 'schema_list'
    | 'schema_create'
    | 'schema_update'
    | 'schema_transit'
    | 'schema_retrieve';

export type SrvSchema = {
    title: string;
    entityType: string;
    required?: string[];
    definitions?: {
        [index: string]: any;
    };
    $defs?: {
        [index: string]: any;
    };
    properties: {
        [index: string]: any;
    };
    error?: number;
};
export type SrvEntData = {
    type: string;
    id: string;
    attributes: {
        [index: string]: any;
    };
    relationships: SrvEntRelationshipsData;
    meta: {
        [index: string]: any;
    };
};

export type SrvEntRelationshipsData = {
    [index: string]: {
        data: EntDescription | EntDescription[];
    };
};

export type SrvErrors = {
    errors: SrvError[];
};

export type SrvError = {
    status: string;
    title?: string;
    code: string;
    source?: {
        pointer: string;
        parameter: string;
    };
    detail: string;
    meta: any;
};

export type SearchSettings = {
    entityType: string;
    fieldForId?: string;
    suffix?: string;
    params?: { [index: string]: string };
    limit?: number;
    paramsGenerator?: Function;
};

export type GroupRequestItem = {
    entityType: string;
    entityId?: string;
    requestType: 'list' | 'item' | 'any';
    meta: string[];
    limit?: number;
    offset?: number;
    sort?: number;
    params: Object;
    body?: Object;
    method?: 'PATCH' | 'POST' | 'GET' | 'DELETE';
};

export type MediaDescription = {
    src: string;
    type: 'image' | 'video' | 'plot';
    name?: string;
    data?: ArrayBuffer;
    alt?: string;
    isLoaded?: boolean;
};

export type IncludedEntData = {
    id: string;
    entityType: string;
    action: string;
    attributes: any;
    relationships: relationshipsEntData;
};

export type relationshipsEntData = {
    [index: string]: string | string[] | EntDescription | EntDescription[];
};
