import { Pipe, PipeTransform } from '@angular/core';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisConfigurationService } from '@bazis/configuration.service';

@Pipe({
    name: 'enum',
    standalone: true,
})
export class EnumPipe implements PipeTransform {
    constructor(private entity: BazisEntityService, private config: BazisConfigurationService) {}

    transform(enumValue: string, type: string, property: string) {
        type = type.replace('.', '__');
        const attribute = this.config.schemas[`${type}__schema_retrieve`].attributes[property];
        return enumValue ? attribute.enumDict[enumValue] : null;
    }
}
