import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { InfiniteListComponent } from '@bazis/shared/components/infinite-list.component';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-loadmore-list',
    templateUrl: './loadmore-list.component.html',
    standalone: true,
    imports: [ButtonModule, IconModule, CommonModule, ColorDirective, TranslocoModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadMoreListComponent extends InfiniteListComponent {
    @HostBinding('class') get hostStyle() {
        return {
            'bazis-list': false,
            'bazis-list_control-dd': false,
        };
    }
}
