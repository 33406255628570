import { Injectable } from '@angular/core';
import { combineLatest, fromEvent, mergeMap, Observable, of, shareReplay, switchMap } from 'rxjs';
import { catchError, debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { EntData, EntDocumentSettings } from '@bazis/shared/models/srv.types';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { CryptoSigningComponent } from '@bazis/signature/crypto/components/signing/signing.component';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { HttpClient } from '@angular/common/http';
import { BazisConfigurationService, SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import moment from 'moment';
import { DocEntData, DocListItem } from '@bazis/shared/models/document.types';
import { DocusignSigningComponent } from '@bazis/signature/docusign/components/signing/signing.component';

@Injectable({
    providedIn: 'root',
})
export class BazisDocumentService {
    constructor(
        private srv: BazisSrvService,
        private http: HttpClient,
        private modalService: BazisModalService,
        private entityService: BazisEntityService,
        private configurationService: BazisConfigurationService,
    ) {}

    createGroupToSignBySettings(
        settings: EntDocumentSettings[],
        forceLoadEntitiesAfterSigning = true,
        signingComponent = null,
    ): Observable<EntDocumentSettings[]> {
        const documentSettings = new TemplateObservable(settings);
        this.openModalToSignDocuments(
            documentSettings,
            signingComponent,
            forceLoadEntitiesAfterSigning,
        );
        return documentSettings.$;
    }

    getSignDocument$(
        entityType: string,
        entityId: string,
        contextLabel: string = null,
        documentPayload: any = {},
        cancelPreviousSignings: boolean = false,
    ): Observable<EntData> {
        return cancelPreviousSignings
            ? this.srv.cancelEntityDocumentSignings$(entityType, entityId, contextLabel).pipe(
                  switchMap(() =>
                      this.entityService.getEntity$(entityType, entityId, { forceLoad: true }),
                  ),
                  switchMap(() =>
                      this.srv.fetchEntityDocument$(
                          entityType,
                          entityId,
                          contextLabel,
                          documentPayload,
                      ),
                  ),
              )
            : this.srv.fetchEntityDocument$(entityType, entityId, contextLabel, documentPayload);
    }

    signEntityDocument$(settings: EntDocumentSettings, signature, signBodyPayload = null) {
        return this.srv.signEntity$(settings, signature, signBodyPayload).pipe(
            mergeMap(() =>
                this.entityService.getEntity$(settings.entityType, settings.entityId, {
                    forceLoad: true,
                    include: settings.withoutIncludedDocuments ? [] : ['documents'],
                }),
            ),
            take(1),
        );
    }

    signEntityDocuments$(
        signSettings: { settings: EntDocumentSettings; signature; signBodyPayload }[],
        forceLoadEntitiesAfterSigning = true,
    ) {
        return this.srv.signEntities$(signSettings).pipe(
            mergeMap(() =>
                this.updateSignedEntities$(
                    signSettings.map((v) => v.settings),
                    forceLoadEntitiesAfterSigning,
                ),
            ),
            catchError((e) => {
                this.modalService.dismiss();
                throw e;
            }),
        );
    }

    updateSignedEntities$(signSettings: EntDocumentSettings[], forceLoadEntitiesAfterSigning) {
        if (!forceLoadEntitiesAfterSigning || signSettings.length === 0) return of([]);
        const entities = [];
        signSettings.forEach((setting) => {
            if (
                !entities.find(
                    (entity) =>
                        entity.entityType === setting.entityType &&
                        entity.entityId === setting.entityId,
                )
            ) {
                entities.push(setting);
            }
        });
        return combineLatest([
            ...entities.map((entity) =>
                this.entityService.getEntity$(entity.entityType, entity.entityId, {
                    forceLoad: true,
                    include: entity.withoutIncludedDocuments ? [] : ['documents'],
                }),
            ),
        ]).pipe(filter((items) => items.filter((v) => !!v).length === items.length));
    }

    protected openModalToSignDocuments(
        settings: TemplateObservable<EntDocumentSettings[]>,
        signingComponent = null,
        forceLoadEntitiesAfterSigning = true,
    ) {
        if (!signingComponent) {
            signingComponent =
                this.configurationService.signatureType === 'crypto'
                    ? CryptoSigningComponent
                    : DocusignSigningComponent;
        }
        const modal = this.modalService.create({
            component: signingComponent,
            componentProperties: {
                itemsToSign: settings,
                forceLoadEntitiesAfterSigning,
            },
            modalType:
                this.configurationService.signatureType === 'crypto'
                    ? 'signing'
                    : 'docusign signing',
        });

        modal
            .onDidDismiss()
            .then((response) => {
                if (!response) {
                    settings.set(null);
                    return null;
                }
                const remainToSign = response.signed
                    ? settings._.filter(
                          (entity) =>
                              !response.signed[
                                  `${entity.entityType}-${entity.entityId}-${
                                      entity.contextLabel || ''
                                  }`
                              ],
                      )
                    : settings._;
                //settings.set(remainToSign);
                settings.set(remainToSign.length > 0 ? null : remainToSign);
                return response;
            })
            .catch((error) => {
                console.log(error);
                settings.set(null);
            });
    }

    getFileContentToSign$(url) {
        return this.http
            .get(url, {
                responseType: 'blob',
            })
            .pipe(
                mergeMap((blob: any) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    return fromEvent(reader, 'load').pipe(
                        map((event) => {
                            let header = ';base64,';
                            let sFileData = reader.result as string;
                            return sFileData.substr(sFileData.indexOf(header) + header.length);
                        }),
                        take(1),
                    );
                }),
            );
    }

    static generateSignDocumentsMap(signDocuments: any[]) {
        const result = {};
        signDocuments.forEach((document) => {
            result[document.id] = {
                ...document,
                attributes: {
                    ...document.attributes,
                },
            };
        });
        return result;
    }

    static generateAvailiableDocuments(documents: EntData[], signDocuments: any[]) {
        const signTargetsMap = BazisDocumentService.generateSignDocumentsMap(signDocuments);
        return documents.filter(
            (document) =>
                signTargetsMap[document.$snapshot.id] &&
                signTargetsMap[document.$snapshot.id].attributes.signatories.length > 0,
        );
    }

    getDocList$(entity$: Observable<EntData>, doNotInitDocLoad = false): Observable<EntData[]> {
        return entity$.pipe(
            filter((v) => !!v),
            switchMap((entity) => {
                return entity.$snapshot.documents.length > 0
                    ? combineLatest(
                          entity.$snapshot.documents.map((doc) =>
                              this.entityService.getEntity$(doc.type, doc.id, {
                                  doNotInitLoad: doNotInitDocLoad,
                              }),
                          ),
                      ).pipe(
                          debounceTime(0),
                          filter(
                              (docs: EntData[]) =>
                                  doNotInitDocLoad ||
                                  docs.length === docs.filter((v) => !!v).length,
                          ),
                          map((docs) => docs.filter((doc) => !!doc)),
                          shareReplay(SHARE_REPLAY_SETTINGS),
                      )
                    : of([]);
            }),
            map((listResult) => {
                return listResult || [];
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    getSignDocuments(entity) {
        const signDocuments = entity ? entity.$snapshot.sign_documents : [];

        return signDocuments.map((v) => {
            let signatories = v.attributes.signatories;

            if (this.configurationService.signatureType === 'docusign') {
                signatories = [...v.attributes.envelope_items];
            }

            return {
                ...v,
                attributes: {
                    ...v.attributes,
                    signatories,
                },
            };
        });
    }

    protected getSignDocuments$(entity$: Observable<EntData>): Observable<any[]> {
        return entity$.pipe(
            map((entity) => this.getSignDocuments(entity)),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    getEntityDocuments$(
        entity$: Observable<EntData>,
        doNotInitDocLoad = false,
    ): Observable<DocListItem[]> {
        const list$: Observable<EntData[]> = this.getDocList$(entity$, doNotInitDocLoad);
        const signDocs$: Observable<EntData[]> = this.getSignDocuments$(entity$);
        return combineLatest([list$, signDocs$]).pipe(
            map(([documentList, signDocs]) => {
                const signMap = (signDocs || []).reduce(
                    (acc, current) => ({ ...acc, [current.id]: current }),
                    {},
                );
                documentList = documentList
                    .map(
                        (v): DocEntData => ({
                            ...v,
                            $timestamp: moment(v.$snapshot.dt_created).valueOf(),
                        }),
                    )
                    .sort((a, b) => b.$timestamp - a.$timestamp);

                const notDigitalDocs = documentList.filter((v) => !v.$snapshot.is_digital);

                return notDigitalDocs
                    .map((notDigitalDoc: EntData) => {
                        const popupDocument = notDigitalDoc;
                        const displayDocument =
                            documentList.find((v) => v.$snapshot.origin_id === notDigitalDoc.id) ||
                            popupDocument;
                        const signInfo = signMap[popupDocument.id]
                            ? {
                                  id: signMap[popupDocument.id].id,
                                  type: signMap[popupDocument.id].type,
                                  $snapshot: signMap[popupDocument.id].attributes,
                              }
                            : null;
                        return { displayDocument, popupDocument, signInfo };
                    })
                    .filter(
                        (v) =>
                            v.displayDocument &&
                            v.signInfo &&
                            v.signInfo.$snapshot.signatories.length > 0,
                    )
                    .sort((a, b) => b.displayDocument.$timestamp - a.displayDocument.$timestamp);
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }
}
