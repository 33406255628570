import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { TranslocoService } from '@jsverse/transloco';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { of, shareReplay, switchMap, combineLatest, Observable } from 'rxjs';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { EntData } from '@bazis/shared/models/srv.types';

const DEFAULT_META_SETTINGS = {
    title: {
        key: 'meta.default.title',
    },
    description: {
        key: 'meta.default.description',
    },
    keywords: { key: 'meta.default.keywords' },
};

export type MetaSettings = {
    title?: any;
    description?: any;
    keywords?: any;
    entities?: any;
    routeParams?: any;
    queryParams?: any;
    isList?: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class BazisMetaService {
    metaLoaded$ = this.translocoService.events$.pipe(
        filter(
            (e) =>
                e.type === 'translationLoadSuccess' && e.payload.scope === 'meta' && !e.wasFailure,
        ),
    );

    navigationEnded$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    protected routerChange$ = combineLatest([this.navigationEnded$, this.metaLoaded$]).pipe(
        map(() => this.getMetaSettings(this.activatedRoute.root)),
        switchMap((settings) =>
            combineLatest([
                of(settings),
                this.getMetaEntities$(settings.entities, settings.routeParams),
            ]),
        ),
        tap(([settings, entities]) => {
            this.buildAllMeta(settings, entities);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected titleService: Title,
        protected authService: BazisAuthService,
        protected translocoService: TranslocoService,
        protected metaService: Meta,
        protected entityService: BazisEntityService,
    ) {
        this.routerChange$.subscribe();
    }

    buildAllMeta(settings, entities) {
        const optionalParams = {
            ...settings.queryParams,
            ...settings.routeParams,
        };
        const title = this.buildMetaItemParams(settings.title, entities, optionalParams);
        const description = this.buildMetaItemParams(
            settings.description,
            entities,
            optionalParams,
        );
        const keywords = this.buildMetaItemParams(settings.keywords, entities, optionalParams);

        this.titleService.setTitle(
            title?.key
                ? this.translocoService.translate(title.key, title.params)
                : title?.field
                ? title.params[title.field]
                : null,
        );

        this.setTag(
            'description',
            description?.key
                ? this.translocoService.translate(description.key, description.params)
                : description?.field
                ? description.params[description.field]
                : null,
        );
        this.setTag(
            'keywords',
            keywords?.key
                ? this.translocoService.translate(keywords.key, keywords.params)
                : keywords?.field
                ? keywords.params[keywords.field]
                : null,
        );
    }

    setTag(tag, value) {
        const selector = `name=${tag}`;
        const existedTag = this.metaService.getTag(selector);

        if (!value && !existedTag) return;

        if (!value && existedTag) {
            this.metaService.removeTag(selector);
            return;
        }

        if (existedTag) {
            this.metaService.updateTag({ name: tag, content: value });
            return;
        }

        this.metaService.addTag({ name: tag, content: value });
    }

    buildMetaItemParams(item, entities, optionalParams = {}) {
        if (!item) return null;

        if (item.generatorFn) return item.generatorFn(entities);

        if (!item.paramSettings) return { key: item.key, params: { ...optionalParams } };

        const params: any = { ...optionalParams };
        Object.keys(item.paramSettings).forEach((key) => {
            params[key] = null;
            if (item.paramSettings[key].generatorFn) {
                params[key] = item.paramSettings[key].generatorFn(
                    entities[item.paramSettings[key].entity],
                );
            } else {
                params[key] =
                    entities[item.paramSettings[key].entity].$snapshot[
                        item.paramSettings[key].field
                    ];
            }
        });

        return { key: item.key, field: item.field, params };
    }

    getMetaEntities$(entities, routeParams): Observable<{ [index: string]: EntData }> {
        if (!entities) return of(null);

        const keys = Object.keys(entities);
        if (!keys.length) return of(null);

        return combineLatest(
            keys.map((key) =>
                this.entityService
                    .getEntity$(entities[key].entityType, routeParams[entities[key].idParam], {
                        doNotInitLoad: true,
                    })
                    .pipe(filter((v) => !!v)),
            ),
        ).pipe(
            map((entities: EntData[]) => {
                const entitiesMap = {};
                keys.forEach((key, index) => {
                    entitiesMap[key] = entities[index];
                });
                return entitiesMap;
            }),
        );
    }

    getMetaSettings(route: ActivatedRoute): MetaSettings {
        let meta = DEFAULT_META_SETTINGS;
        let routeParams = {};
        let queryParams = {};

        const processRoute = (route) => {
            routeParams = { ...routeParams, ...route.snapshot.params };
            queryParams = { ...queryParams, ...route.snapshot.queryParams };
            if (route.snapshot.data.meta) meta = route.snapshot.data.meta;
            route.children.forEach((child: ActivatedRoute) => {
                if (!route.children?.length) return;
                processRoute(child);
            });
        };

        processRoute(route);
        return { ...meta, routeParams, queryParams };
    }
}
