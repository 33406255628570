import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fileDownloaderFn } from '@bazis/utils';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { LinkModule } from '@bazis/shared/components/web/link/link.module';
import { LocalDatePipe } from '@bazis/shared/pipes/local-date.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { CommonModule } from '@angular/common';
import { EntityPipe } from '@bazis/shared/pipes/entity.pipe';

@Component({
    selector: 'bazis-signature',
    templateUrl: './signature.component.html',
    standalone: true,
    imports: [
        LinkModule,
        IconModule,
        ButtonModule,
        LocalDatePipe,
        ColorDirective,
        TranslocoModule,
        CommonModule,
        EntityPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisSignatureComponent {
    @Input()
    signatureId: string;

    showSignature = false;

    constructor() {}

    downloadSign(signature) {
        fileDownloaderFn(`data:application/zip;base64,${signature}`, 'Электронная_подпись.sig');
    }
}
