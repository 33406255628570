<svg
    class="bazis-star"
    [class.bazis-star--active]="isActive"
    [attr.width]="size"
    [attr.height]="size"
>
    <use *ngIf="!isHalf" xlink:href="#star"></use>
    <ng-container *ngIf="isHalf">
        <use
            xlink:href="#star"
            mask="url(#half-right)"
        ></use>
        <use
            xlink:href="#star"
            fill="var(--star-inactive)"
            mask="url(#half-left)"
        ></use>
    </ng-container>
</svg>

<!-- Переиспользуемый SVG-шаблон -->
<svg
    width="0"
    height="0"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    [attr.viewBox]="'0 0 ' + size + ' ' + size"
>
    <!-- Маска, которая будет закрывать часть звезды -->
    <mask id="half-left">
        <rect
            x="0"
            y="0"
            [attr.width]="size"
            [attr.height]="size"
        />
        <rect
            x="50%"
            y="0"
            [attr.width]="size"
            [attr.height]="size"
            fill="white"
        />
    </mask>
    <mask id="half-right">
        <rect
            x="0"
            y="0"
            [attr.width]="size"
            [attr.height]="size"
            fill="white"
        />
        <rect
            x="50%"
            y="0"
            [attr.width]="size"
            [attr.height]="size"
        />
    </mask>

    <!-- Фигура звезды -->
    <symbol
        xmlns="http://www.w3.org/2000/svg"
        [attr.viewBox]="'0 0 ' + size + ' ' + size"
        id="star"
    >
        <path [attr.d]="svgStarPath" />
    </symbol>
</svg>
