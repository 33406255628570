import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { BazisConfigurationService, VEHICLE_MASK_SETTINGS } from '@bazis/configuration.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-vehicle-number',
    templateUrl: './vehicle-number.component.html',
    standalone: true,
    imports: [CommonModule, ColorDirective, TranslocoModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleNumberComponent implements OnDestroy, OnChanges {
    @Input()
    vehicleNumber: string;

    @Input()
    maskSettings = VEHICLE_MASK_SETTINGS;

    @Input()
    regionSuffix: string = 'rus';

    parts = [];

    constructor(private configurationService: BazisConfigurationService) {}

    ngOnDestroy(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        let end = 0;
        let group = this.configurationService.vehicleNumberMask;
        if ('vehicleNumberDisplay' in this.configurationService) {
            group = this.configurationService.vehicleNumberDisplay;
        }
        this.parts = group.map((maskGroup) => {
            const start = end;
            const maxEnd = start + maskGroup.length;

            let currentValue = '';

            let maxValue = this.vehicleNumber.substring(start, maxEnd).toUpperCase().split('');
            maskGroup.split('').forEach((maskSymbol, indexMaskSymbol) => {
                if (maxValue.length < indexMaskSymbol) return;

                if (
                    this.maskSettings.patterns[maskSymbol].pattern.test(maxValue[indexMaskSymbol])
                ) {
                    currentValue += maxValue[indexMaskSymbol];
                }
            });

            end += currentValue.length;

            return currentValue.toUpperCase();
        });
    }
}
