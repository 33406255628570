import { Inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { BazisLocaleService } from '@bazis/shared/services/locale.service';
import { DATETIME_LOCALE } from '@bazis/configuration.service';

export const DATETIME_LOCALE_DATA = new InjectionToken<any>('', {
    providedIn: 'root',
    factory: () => DATETIME_LOCALE,
});

@Pipe({
    name: 'localDate',
    pure: false,
    standalone: true,
})
export class LocalDatePipe implements PipeTransform {
    constructor(
        protected localeService: BazisLocaleService,
        @Inject(DATETIME_LOCALE_DATA) protected datetimeLocale,
    ) {}

    transform(value: any, format: string, isUtc = false, locale = this.localeService.locale) {
        if (!value) {
            return '';
        }

        let finalFormat = this.datetimeLocale[locale][format] || format || 'shortDate';
        const timezone = isUtc ? '+00:00' : '';
        return formatDate(value, finalFormat, locale, timezone);
    }
}
