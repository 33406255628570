<ng-container *transloco="let t">
    <div
        class="bazis-datepicker"
        *ngIf="calendars$ | async as calendars"
    >
        <div
            class="bazis-calendar"
            *ngFor="let calendar of calendars; let calendarIndex = index"
        >
            <div
                class="bazis-calendar__head"
                [class.bazis-calendar__head_left]="typeHeadCalendar === 'left'"
                part="calendar-head"
            >
                <bazis-button
                    *ngIf="calendar.isFirst && typeHeadCalendar === 'center'"
                    [part]="
                        calendarsNumber > 1
                            ? 'button head-button interval-head-button'
                            : 'button head-button'
                    "
                    [ngClass]="{
                        'bazis-calendar__btn': true,
                        'bazis-calendar__btn_interval': calendarsNumber > 1
                    }"
                    [fill]="calendarsNumber > 1 ? 'outline' : 'clear'"
                    [disabled]="calendar.disabledPrevMonth"
                    (click)="stepPrevMonth()"
                >
                    <bazis-icon
                        slot="icon-only"
                        name="angle-small-left"
                    ></bazis-icon>
                </bazis-button>
                <div
                    class="bazis-calendar__value"
                    part="value"
                >
                    <div class="bazis-dropdown">
                        <div
                            *ngIf="typeSelectMonth === 'list' || monthListOpened !== calendarIndex"
                            class="bazis-dropdown__trigger"
                            (click)="toogleMonthList(calendarIndex)"
                        >
                            {{ t('months.long.' + monthNames[calendar.calendarMonth]) }}
                        </div>
                        <div
                            *ngIf="typeSelectMonth === 'list'"
                            class="bazis-dropdown__body"
                            [hidden]="monthListOpened !== calendarIndex"
                        >
                            <ul
                                class="bazis-list"
                                part="list"
                            >
                                <li
                                    class="bazis-item"
                                    part="list-item"
                                    *ngFor="let month of calendar.monthList"
                                    [ngClass]="{
                                        'bazis-item--selected': month.isCurrent,
                                        'bazis-item--disabled': month.isMuted
                                    }"
                                    (click)="
                                        changeCurrentMonth(month); toogleMonthList(calendarIndex)
                                    "
                                >
                                    <span> {{ t('months.long.' + month.name) }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="bazis-dropdown">
                        <div
                            class="bazis-dropdown__trigger"
                            (click)="toogleYearsList(calendarIndex)"
                        >
                            {{ calendar.calendarYear }}
                        </div>
                        <div
                            class="bazis-dropdown__body"
                            [hidden]="yearsListOpened !== calendarIndex"
                        >
                            <!-- <bazis-button
                                part="button"
                                class="bazis-calendar__btn"
                                fill="clear"
                                [disabled]="calendar.disabledPrevYear"
                                (click)="stepPrevYear()"
                            >
                                <bazis-icon
                                    slot="icon-only"
                                    name="angle-small-up"
                                ></bazis-icon>
                            </bazis-button> -->
                            <ul
                                class="bazis-list"
                                part="list list-year"
                                #listYear
                            >
                                <li
                                    class="bazis-item"
                                    part="list-item"
                                    *ngFor="let year of calendar.yearList"
                                    [ngClass]="{
                                        'bazis-item--selected': year.isCurrent,
                                        'bazis-item--disabled': year.isMuted
                                    }"
                                    [id]="year.isCurrent ? 'yearSelected' : ''"
                                    (click)="
                                        changeCurrentYear(year.id); toogleYearsList(calendarIndex)
                                    "
                                >
                                    <span>{{ year.id }}</span>
                                </li>
                            </ul>
                            <!-- <bazis-button
                                part="button"
                                class="bazis-calendar__btn"
                                fill="clear"
                                [disabled]="calendar.disabledNextYear"
                                (click)="stepNextYear()"
                            >
                                <bazis-icon
                                    slot="icon-only"
                                    name="angle-small-down"
                                ></bazis-icon>
                            </bazis-button> -->
                        </div>
                    </div>
                </div>

                <div class="bazis-calendar__head__buttons">
                    <bazis-button
                        *ngIf="calendar.isFirst && typeHeadCalendar === 'left'"
                        [part]="
                            calendarsNumber > 1
                                ? 'button head-button interval-head-button'
                                : 'button head-button'
                        "
                        [ngClass]="{
                            'bazis-calendar__btn': true,
                            'bazis-calendar__btn_interval': calendarsNumber > 1
                        }"
                        [fill]="calendarsNumber > 1 ? 'outline' : 'clear'"
                        [disabled]="calendar.disabledPrevMonth"
                        (click)="stepPrevMonth()"
                    >
                        <bazis-icon
                            slot="icon-only"
                            name="angle-small-left"
                        ></bazis-icon>
                    </bazis-button>
                    <bazis-button
                        [part]="
                            calendarsNumber > 1
                                ? 'button head-button interval-head-button'
                                : 'button head-button'
                        "
                        [ngClass]="{
                            'bazis-calendar__btn': true,
                            'bazis-calendar__btn_interval': calendarsNumber > 1
                        }"
                        [fill]="calendarsNumber > 1 ? 'outline' : 'clear'"
                        [disabled]="calendar.disabledNextMonth"
                        (click)="stepNextMonth()"
                        *ngIf="calendar.isLast"
                    >
                        <bazis-icon
                            slot="icon-only"
                            name="angle-small-right"
                        ></bazis-icon>
                    </bazis-button>
                </div>
            </div>
            <div
                class="bazis-calendar__body"
                part="calendar-body"
            >
                <table
                    *ngIf="typeSelectMonth !== 'grid' || monthListOpened !== calendarIndex"
                    class="bazis-calendar__table"
                >
                    <thead part="thead">
                        <tr>
                            <th
                                class="bazis-calendar__weekday"
                                *ngFor="let dayNameShort of weekDaysNamesShort"
                            >
                                {{ t('weekDays.short.' + dayNameShort) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of calendar.daysMatrix">
                            <ng-container *ngFor="let date of row">
                                <td
                                    *ngIf="date.isValid"
                                    class="bazis-calendar__day"
                                    [ngClass]="{
                                        'bazis-calendar__day_current': date.isToday,
                                        'bazis-calendar__day--selected': date.isSelected,
                                        'bazis-calendar__day--muted': date.isMuted,
                                        'bazis-calendar__day_interval': date.isInInterval,
                                        'bazis-calendar__day_interval_last': date.isInIntervalLast,
                                        'bazis-calendar__day_interval_first': date.isInIntervalFirst
                                    }"
                                    (click)="
                                        handleDayClick(
                                            date,
                                            calendar.calendarYear,
                                            calendar.calendarMonth
                                        )
                                    "
                                >
                                    <span>{{ date.dayNumber }}</span>
                                    <span
                                        *ngIf="date.mark"
                                        part="mark"
                                        class="bazis-calendar__day__mark"
                                        [class]="'bazis-calendar__day__mark--' + date.mark"
                                    >
                                    </span>
                                </td>
                                <td *ngIf="!date.isValid"></td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
                <div
                    *ngIf="typeSelectMonth === 'grid' && monthListOpened === calendarIndex"
                    class="bazis-calendar__grid bazis-list"
                >
                    <div
                        class="bazis-item"
                        part="grid-item"
                        *ngFor="let month of calendar.monthList"
                        [ngClass]="{
                            'bazis-item--selected': month.isCurrent,
                            'bazis-item--disabled': month.isMuted
                        }"
                        (click)="changeCurrentMonth(month); toogleMonthList(calendarIndex)"
                    >
                        <span> {{ t('months.long.' + month.name) }}</span>
                    </div>
                </div>
            </div>
            <div
                class="bazis-calendar__foot"
                part="calendar-foot"
            >
                <div class="bazis-btn-group">
                    <bazis-button
                        part="button"
                        class="bazis-calendar__btn"
                        *ngIf="resetBtn.use === true"
                        (click)="clear()"
                    >
                        {{ resetBtn.txt }}
                    </bazis-button>
                    <bazis-button
                        part="button"
                        class="bazis-calendar__btn"
                        *ngIf="currentDateBtn.use === true"
                        (click)="selectTodayDay()"
                    >
                        {{ currentDateBtn.txt || '' }}
                    </bazis-button>
                </div>
                <div
                    class="bazis-btn-group"
                    *ngIf="additionalBtns"
                >
                    <bazis-button
                        part="button"
                        class="bazis-calendar__btn"
                        *ngFor="let additionalBtn of additionalBtns"
                        (click)="additionalBtn.clickFn()"
                    >
                        {{ additionalBtn.txt }}
                    </bazis-button>
                </div>
            </div>
        </div>
        <div
            class="bazis-datepicker__backdrop"
            [hidden]="!(monthListOpened || yearsListOpened)"
            (click)="closeLists()"
        ></div>
    </div>
    <ng-container
        *ngIf="{
            updateMonth: updateMonth$ | async,
            updateYear: updateYear$ | async
        } as data"
    ></ng-container>
</ng-container>
