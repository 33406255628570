import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FileType, FILE_EXTENSIONS } from '@bazis/form/models/form-element.types';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { DocListItem } from '@bazis/shared/models/document.types';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { BazisDocuSignaturesComponent } from '@bazis/document/component/docu-signatures.component';
import { BazisSignaturesComponent } from '@bazis/document/component/signatures.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { TooltipModule } from '@bazis/shared/components/web/tooltip/tooltip.module';
import { FileSizePipe } from '@bazis/shared/pipes/file-size.pipe';
import { LinkModule } from '@bazis/shared/components/web/link/link.module';
import { BadgeModule } from '@bazis/shared/components/web/badge/badge.module';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-signed-file-inline',
    templateUrl: './signed-file-inline.component.html',
    standalone: true,
    imports: [
        CommonModule,
        IconModule,
        ButtonModule,
        TooltipModule,
        FileSizePipe,
        LinkModule,
        BadgeModule,
        TranslocoModule,
        ColorDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignedFileInlineComponent implements OnInit {
    @Input() filesData: DocListItem = null;

    @Input() fileNameKey?: string = '';

    @Input() fileNameParams?: any = null;

    @Input() hasLink: boolean = true;

    @Input() showFileSize: boolean = true;

    @Input() showSignatures: boolean = true;

    // знак вопроса может быть рядом с файлом
    @Input() tooltipKey: string = null;

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    public fileType: FileType = 'any';

    public extentions = FILE_EXTENSIONS[this.fileType];

    public extention: string;

    public srcFile: string = '';

    openDropdownMenu = false;

    componentTypes = {
        crypto: BazisSignaturesComponent,
        docusign: BazisDocuSignaturesComponent,
    };

    constructor(
        private modalService: BazisModalService,
        private configurationService: BazisConfigurationService,
    ) {}

    ngOnInit(): void {
        this.srcFile =
            this.filesData.displayDocument.$snapshot.file_upload_url ||
            this.filesData.displayDocument.$snapshot.file;
        const extensionArr = this.srcFile.split('.');
        const extention = extensionArr[extensionArr.length - 1];
        this.extention = extention.split('?')[0];
    }

    openSignatureDetails() {
        const component = this.componentTypes[this.configurationService.signatureType || 'crypto'];

        this.modalService.create({
            component,
            componentProperties: {
                document: this.filesData.popupDocument,
                signInfo: this.filesData.signInfo,
                site: location.host,
            },
        });
    }

    toggleMenu(event) {
        this.openDropdownMenu = !this.openDropdownMenu;
        event.stopPropagation();
    }
}
