import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { ModalComponent } from '@bazis/shared/components/modal/modal.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'bazis-modals',
    templateUrl: './modals.component.html',
    standalone: true,
    imports: [ModalComponent, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalsComponent implements OnDestroy, OnInit {
    constructor(public modalService: BazisModalService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
