<ng-container *transloco="let t">
    <span
        *ngIf="pin"
        class="bazis-range__pin"
        part="pin"
    >
        {{ range.value }} {{ pinDimension }}
    </span>
    <ng-container
        *ngIf="customPinTemplate"
        [ngTemplateOutlet]="customPinTemplate"
        [ngTemplateOutletContext]="{
            $implicit: '',
            value: range.value,
        }"
    >
    </ng-container>
    <input
        #range
        part="range"
        type="range"
        class="bazis-range__input bazis-range--progress"
        [attr.min]="min"
        [attr.max]="max"
        [attr.step]="step"
        [attr.disabled]="disabled ? '' : null"
        [value]="value"
        (change)="valueChanged.emit(range.value)"
    />

    <div
        *ngIf="showScale"
        class="bazis-range__scale"
        part="scale"
    >
        <div
            *ngFor="let i of scale"
            class="bazis-range__scale__label"
        >
            {{ i }}
        </div>
    </div>
</ng-container>
