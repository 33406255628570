import { Component } from '@angular/core';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-crypto-info',
    templateUrl: './info.component.html',
    standalone: true,
    imports: [ButtonModule, ColorDirective, TranslocoModule, CommonModule],
})
export class CryptoInfoComponent {}
