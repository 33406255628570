import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { PluralPipe } from '@bazis/shared/pipes/plural.pipe';
import { CommonModule } from '@angular/common';
import { LocalDatePipe } from '@bazis/shared/pipes/local-date.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-dateplate',
    templateUrl: './dateplate.component.html',
    standalone: true,
    imports: [IconModule, PluralPipe, CommonModule, LocalDatePipe, TranslocoModule, ColorDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateplateComponent implements OnInit, OnChanges {
    @Input() titleKey;

    @Input() titleParams;

    @Input() count; // кол-во дат в группе

    @Input() date;

    @Input() time;

    @Input() timeStart;

    @Input() timeEnd;

    @Input() hideTime: boolean = false;

    @Input() size: 'xxs' | 'xs' | 's' | 'md' | 'l' | 'xl' = 'xs';

    constructor() {}

    ngOnInit(): void {}

    // не понятно зачем преобразование в тот же формат что и получаем? возможны секунды?
    getHoursAndMinutes(time: string): string {
        const hours = time.slice(0, 2);
        const minutes = time.slice(3, 5);

        return hours + ':' + minutes;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.time) {
            this.time = this.getHoursAndMinutes(this.time);
        }
        if (this.timeStart) {
            this.timeStart = this.getHoursAndMinutes(this.timeStart);
        }
        if (this.timeEnd) {
            this.timeEnd = this.getHoursAndMinutes(this.timeEnd);
        }
    }
}
