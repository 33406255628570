import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { EntDescription } from '@bazis/shared/models/srv.types';

@Pipe({
    name: 'entity',
    standalone: true,
})
export class EntityPipe implements PipeTransform {
    constructor(private entity: BazisEntityService) {}

    transform(
        id: string | EntDescription,
        entityType: string = '',
        forceLoad = false,
        doNotInitLoad = false,
    ) {
        if (!id) return of(null);
        if (id instanceof Object)
            return this.entity.getEntity$(id.type, id.id, { forceLoad, doNotInitLoad });
        return this.entity.getEntity$(entityType, id, { forceLoad, doNotInitLoad });
    }
}
