import { Directive, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
    selector: '[localRouterLink]',
    standalone: true,
})
export class LocalRouterLinkDirective {
    @HostListener('click', ['$event']) onKeydownHandler(event) {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
            const currentUrl = window.location.pathname;
            const url = this.relativeTo
                ? this.router.serializeUrl(
                      this.router.createUrlTree([currentUrl, this.localRouterLink]),
                  )
                : this.localRouterLink;
            const searchParams = this.queryParams
                ? '?' + new URLSearchParams(this.queryParams).toString()
                : '';

            // console.log('LocalRouterLinkDirective', url, searchParams);
            window.open(url + searchParams, '_blank');
        } else {
            this.navigate();
        }
    }

    @Input() localRouterLink: string;

    @Input() relativeTo?: boolean = true;

    @Input() queryParams?;

    constructor(private router: Router, private route: ActivatedRoute) {}

    navigate() {
        this.router.navigate([this.localRouterLink], {
            relativeTo: this.relativeTo ? this.route : null,
            queryParams: {
                ...this.queryParams,
            },
        });
    }
}
