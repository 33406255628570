import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * Add the template content to the DOM if control exists
 */
@Directive({ selector: '[ifControl]', standalone: true })
export class IfControlDirective {
    private hasView = false;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

    private _ifControl;

    @Input() set ifControl(property: AbstractControl | AbstractControl[]) {
        this._ifControl = property;
        this.calculate();
    }

    private _ifControlCondition = 'or';

    @Input() set ifControlCondition(propertyCondition: any) {
        this._ifControlCondition = propertyCondition;
        this.calculate();
    }

    calculate() {
        const props = Array.isArray(this._ifControl) ? this._ifControl : [this._ifControl];
        let show;
        if (this._ifControlCondition === 'or') {
            // show if at least one value is not undefined
            show = props.reduce((acc, current) => acc || current, false);
        } else if (this._ifControlCondition === 'and') {
            // show if all values are not undefined
            show = props.reduce((acc, current) => acc && current, true);
        }

        if (show && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!show && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
