import { Injectable } from '@angular/core';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { fromEvent, shareReplay, withLatestFrom } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { MpAuthService } from '@shared/services/auth.service';
import { BazisMetaService } from '@bazis/shared/services/meta.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    systemNotifications$ = fromEvent(window, 'storage').pipe(
        filter((event: any) => event.key === 'event' && event.newValue),
        withLatestFrom(this.authService.userId$),
        tap(([event, userId]) => {
            if (!event.newValue || !userId) return;
            let value = null;
            try {
                value = JSON.parse(event.newValue);
            } catch {
                console.log('[DEV][ERROR] invalid event data');
            }
            if (!value) return;

            if (value.eventName === 'logout') {
                this.authService.afterLogout();
                this.authService.redirectToHomePage();
            }
            if (value.eventName === 'roleChanged') {
                this.authService.needUpdateUser('socket');
                this.authService.redirectToHomePage();
            }
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        private authService: MpAuthService,
        private metaService: BazisMetaService,
        private srv: BazisSrvService,
    ) {
        this.systemNotifications$.subscribe();
    }
}
