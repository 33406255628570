import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { BehaviorSubject, shareReplay } from 'rxjs';
import { TabsModule } from '@bazis/shared/components/web/tabs/tabs.module';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from '@bazis/shared/components/web/skeleton/skeleton.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';

interface TabItem {
    id: string | number;
    title?: string;
    titleKey?: string;
    titleParams?: any;
    entityType?: string;
    routerLink?: string;
    isExactLink?: boolean;
    disabled?: boolean;
    // name иконки или src
    icon?: string;
    iconPosition?: 'start' | 'end';
    underconstruction?: boolean;
    hidden?: boolean;
}

@Component({
    selector: 'bazis-collapsible-to-more',
    templateUrl: './collapsible-to-more.component.html',
    standalone: true,
    imports: [CommonModule, TabsModule, ColorDirective, SkeletonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleToMoreComponent implements OnInit, OnChanges {
    @Input()
    tabList: TabItem[];

    @Input()
    selectedTab = new TemplateObservable(null);

    @Input()
    hideByZeroCount = false;

    @Input()
    needScroll = true;

    @Output()
    selectedTabChange = new EventEmitter();

    @Input() tabTemplate: TemplateRef<any>;

    onResizeObserver$ = new BehaviorSubject(null);

    // @Input() tabsLoaded: boolean;

    @ViewChild('wrapper') set wrapperEl(el: ElementRef) {
        if (!el) return;
        this.wrapper = el.nativeElement;
    }

    @ViewChild('endPart') set endPartEl(el: ElementRef) {
        if (!el) return;
        this.endPart = el.nativeElement;
    }

    @ViewChild('bazisTabs', { read: ElementRef, static: false }) set bazisTabsEl(el: ElementRef) {
        if (!el) return;
        this.bazisTabs = el;
    }

    @HostListener('window:resize') onResize() {
        this.needResize$.next(true);
    }

    needResize$ = new BehaviorSubject(null);

    observeElementResizing$ = this.needResize$.pipe(
        debounceTime(0),
        tap(() => {
            this.collapsibleTabs();
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    wrapper;

    endPart;

    bazisTabs;

    hasMore$ = new BehaviorSubject(false);

    breaks = [];

    protected moreTabWidth = 132;

    moreInsideItemsCounter = 0;

    isShakeTabs = new TemplateObservable(true);

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tabList?.previousValue && changes.tabList?.currentValue) {
            this.breaks = [];
            this.moreInsideItemsCounter = 0;
            this.needResize$.next(true);
        }
    }

    collapsibleTabs() {
        if (!this.tabList) return;

        let tabsWidth = this.bazisTabs.nativeElement.offsetWidth;

        const availableSpace =
            this.wrapper.offsetWidth - this.endPart.offsetWidth - this.moreTabWidth;

        const lengthBreaks = this.breaks.length;

        if (this.breaks.length > 0) tabsWidth = tabsWidth - this.moreTabWidth;
        if (tabsWidth > availableSpace && this.tabList.length) {
            this.hasMore$.next(true);

            const firstIndexHidden = this.tabList.findIndex((tabItem) => tabItem.hidden);
            const lastIndexVisible =
                firstIndexHidden !== -1 ? firstIndexHidden - 1 : this.tabList.length - 1;
            if (lastIndexVisible > 0) {
                // Record the width of the list
                this.breaks.push(tabsWidth);
                // Move item to the hidden list
                this.tabList[lastIndexVisible].hidden = true;
                if (
                    (this.hideByZeroCount &&
                        this.tabList[lastIndexVisible].titleParams?.count > 0) ||
                    !this.hideByZeroCount
                ) {
                    this.moreInsideItemsCounter++;
                }
            }
        } else {
            if (!lengthBreaks) {
                this.isShakeTabs.set(false);
                this.hasMore$.next(false);
                return;
            }
            // There is space for another item in the nav
            while (this.breaks.length && availableSpace > this.breaks[this.breaks.length - 1]) {
                // Move the item to the visible list
                let firstIndexHidden = this.tabList.findIndex((tab) => tab.hidden);
                this.tabList[firstIndexHidden].hidden = false;
                this.breaks.pop();
                if (
                    (this.hideByZeroCount &&
                        this.tabList[firstIndexHidden].titleParams?.count > 0) ||
                    !this.hideByZeroCount
                ) {
                    this.moreInsideItemsCounter--;
                }
            }

            const hasHiddenItem = this.tabList.some((tab) => tab.hidden);

            if (!hasHiddenItem) this.hasMore$.next(false);
        }

        if (lengthBreaks === this.breaks.length) {
            this.isShakeTabs.set(false);
        } else {
            this.isShakeTabs.set(true);
            this.needResize$.next(true);
        }

        // console.log('[BREAKS]', this.breaks.length);
    }

    changeSelectedTab(e) {
        this.selectedTabChange.emit(e);
    }
}
