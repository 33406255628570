import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FileType, FILE_EXTENSIONS } from '@bazis/form/models/form-element.types';
import { EntData } from '@bazis/shared/models/srv.types';
import { LinkModule } from '@bazis/shared/components/web/link/link.module';
import { BadgeModule } from '@bazis/shared/components/web/badge/badge.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { TooltipModule } from '@bazis/shared/components/web/tooltip/tooltip.module';
import { FileSizePipe } from '@bazis/shared/pipes/file-size.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-file-inline',
    templateUrl: './file-inline.component.html',
    standalone: true,
    imports: [
        LinkModule,
        BadgeModule,
        IconModule,
        TooltipModule,
        FileSizePipe,
        TranslocoModule,
        CommonModule,
        ColorDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInlineComponent implements OnInit {
    @Input() file: EntData = null;

    @Input() fileNameKey?: string = '';

    @Input() fileNameParams?: any = null;

    @Input() hasLink: boolean = true;

    @Input() showFileSize: boolean = true;

    // знак вопроса может быть рядом с файлом
    @Input() tooltipKey: string = null;

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    @Input() fileInfo: {
        id: string;
        url: string;
        name: string;
        extension: string;
        size: number;
    } = null;

    public fileType: FileType = 'any';

    public extentions = FILE_EXTENSIONS[this.fileType];

    public extention: string;

    public srcFile: string = '';

    public nameFile: string = '';

    public sizeFile: number;

    ngOnInit(): void {
        this.srcFile =
            this.file?.$snapshot.file_upload_url || this.file?.$snapshot.file || this.fileInfo.url;
        const extensionArr = this.srcFile.split('.');
        const extention = extensionArr[extensionArr.length - 1];
        this.extention = extention.split('?')[0];
        this.nameFile = this.file?.$snapshot.name || this.fileInfo.name;
        this.sizeFile = this.file?.$snapshot.size || this.fileInfo.size;
    }
}
