import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-star',
    templateUrl: './star.component.html',
    styleUrls: ['star.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class StarElement {
    @HostBinding('style') get starStyle() {
        return {
            [`--size`]: this.size,
        };
    }

    @Input() size: number = 16;

    @Input() svgStarPath: string =
        'M12.978 15.5437L8.00001 11.8851L3.02201 15.5437L4.93334 9.63506L-0.0419922 5.99972H6.10067L8.00001 0.0810547L9.89934 5.99972H16.0413L11.0667 9.63506L12.978 15.5437Z';

    @Input() isActive: boolean = false;

    @Input() isHalf: boolean = false;
}
