import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { createCustomElement } from '@angular/elements';
import { TripLineElement } from '@bazis/shared/components/web/trip-line/trip-line.component';

@NgModule({
    declarations: [TripLineElement],
    imports: [CommonModule],
    exports: [TripLineElement],
})
export class TripLineModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(TripLineElement, {
            injector: this.injector,
        });

        customElements.define('bazis-trip-line', el);
    }
}
