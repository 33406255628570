import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccordionElement } from './accordion.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ToolbarModule } from '@bazis/shared/components/web/toolbar/toolbar.module';

@NgModule({
    declarations: [AccordionElement],
    imports: [CommonModule, IconModule, ToolbarModule],
    exports: [AccordionElement],
})
export class AccordionModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(AccordionElement, {
            injector: this.injector,
        });

        customElements.define('bazis-accordion', el);
    }
}
