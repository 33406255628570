import { Inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import { PHONE_INPUT_TRANSFORM_FN, PHONE_MASK_SETTINGS } from '@bazis/configuration.service';
import { NgxMaskService } from 'ngx-mask';

export type PhonePipeSettings = {
    pattern: string;
    prefix: string;
    transform: Function;
};

export const PHONE_PIPE_DEFAULT_SETTINGS = new InjectionToken<PhonePipeSettings>('', {
    providedIn: 'root',
    factory: () => ({
        pattern: PHONE_MASK_SETTINGS.mask,
        prefix: PHONE_MASK_SETTINGS.prefix,
        transform: PHONE_INPUT_TRANSFORM_FN,
    }),
});
@Pipe({
    name: 'phone',
    standalone: true,
})
export class PhonePipe implements PipeTransform {
    constructor(
        protected maskService: NgxMaskService,
        @Inject(PHONE_PIPE_DEFAULT_SETTINGS) public defaultSettings: PhonePipeSettings,
    ) {}

    transform(
        value: string,
        pattern = this.defaultSettings.pattern,
        prefix = this.defaultSettings.prefix,
        transformFn = this.defaultSettings.transform,
    ): string {
        if (!pattern || !value) return value;
        if (transformFn) value = transformFn(value);
        value = this.maskService.applyMask(value, pattern);
        return prefix ? `${prefix}${value}` : value;
    }
}
