import { ChangeDetectionStrategy, Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { ToolbarModule } from '@bazis/shared/components/web/toolbar/toolbar.module';
import { DummyMobileComponent } from '@bazis/shared/components/dummy-mobile/dummy-mobile.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-dummy-modal',
    templateUrl: './dummy-modal.component.html',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        ToolbarModule,
        DummyMobileComponent,
        ColorDirective,
        TranslocoModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DummyModalComponent implements OnInit, OnDestroy {
    @HostListener('window:resize') onResize() {
        if (this.mqSrv.matchBreakpoint('xl')) {
            this.modalService.dismiss();
        }
    }

    constructor(
        private modalService: BazisModalService,
        private mqSrv: BazisMediaQueryService,
        private router: Router,
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    close() {
        if (this.mqSrv.matchBreakpoint('xl')) {
            this.modalService.dismiss();
        }
        if (this.mqSrv.inIntervalBreakpoint('xs', 'xl')) {
            this.router.navigate(['/'], {
                replaceUrl: true,
            });
            this.modalService.dismiss();
        }
    }
}
