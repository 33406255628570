import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { delay, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
    merge,
    Observable,
    of,
    shareReplay,
    combineLatest,
    timer,
    Subject,
    withLatestFrom,
    BehaviorSubject,
} from 'rxjs';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { BazisCryptoService } from '@bazis/signature/crypto/crypto.service';
import { EntData, EntDocumentSettings, ItemToSign } from '@bazis/shared/models/srv.types';
import { BazisDocumentService } from '@bazis/shared/services/document.service';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { BazisDocusignService } from '@bazis/signature/docusign/docusign.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisAlertService } from '@bazis/shared/services/alert.service';
import { CommonModule, NgClass } from '@angular/common';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ToolbarModule } from '@bazis/shared/components/web/toolbar/toolbar.module';
import { FileInlineComponent } from '@bazis/shared/components/file/file-inline.component';
import { DefaultLoaderComponent } from '@bazis/shared/components/loaders/default-loader.component';
import { DocumentAccordeonComponent } from '@bazis/shared/components/document-accordeon/document-accordeon.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-docusign-signing',
    templateUrl: './signing.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        IconModule,
        ToolbarModule,
        CommonModule,
        FileInlineComponent,
        DefaultLoaderComponent,
        DocumentAccordeonComponent,
        ColorDirective,
        TranslocoModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocusignSigningComponent implements OnInit {
    constructor(
        private crypto: BazisCryptoService,
        private modalService: BazisModalService,
        private documentService: BazisDocumentService,
        public mqSrv: BazisMediaQueryService,
        public srvService: BazisSrvService,
        public authService: BazisAuthService,
        public docusignService: BazisDocusignService,
        public entityService: BazisEntityService,
        public alertService: BazisAlertService,
    ) {}

    @Input() itemsToSign: TemplateObservable<EntDocumentSettings[]>;

    @Input() forceLoadEntitiesAfterSigning: boolean;

    itemsToSign$: Observable<ItemToSign[]>;

    allItems = [];

    signed = {};

    allSigned$: Observable<{ checkSignature$: Observable<EntData> }[]>;

    signingDoc$: Observable<any>;

    indexToSign$: Subject<number> = new BehaviorSubject(0);

    startCheck$ = new BehaviorSubject(null);

    toCheck = [];

    allChecked$: Observable<any>;

    step$ = new BehaviorSubject(0);

    filesIsUploaded = new TemplateObservable(0);

    ngOnInit() {
        this.itemsToSign$ = this.itemsToSign.$.pipe(
            tap((itemsToSign) => {
                this.allItems = itemsToSign;
            }),
            map((itemsToSign) => {
                if (!itemsToSign) return [];
                return itemsToSign.map((item) => {
                    const documentSource = item.document
                        ? of({ ...item.document }).pipe(shareReplay(SHARE_REPLAY_SETTINGS))
                        : this.documentService
                              .getSignDocument$(
                                  item.entityType,
                                  item.entityId,
                                  item.contextLabel,
                                  item.documentPayload,
                                  item.cancelPreviousSignings,
                              )
                              .pipe(shareReplay(SHARE_REPLAY_SETTINGS));
                    return {
                        ...item,
                        document$: documentSource.pipe(
                            take(1),
                            delay(1),
                            tap((v) => {
                                this.filesIsUploaded.set(this.filesIsUploaded._ + 1);
                                if (this.allItems.length === 1) this.step$.next(1);
                            }),
                            shareReplay({ bufferSize: 1, refCount: false }),
                        ),
                    };
                });
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        this.signingDoc$ = this.indexToSign$.pipe(
            withLatestFrom(this.itemsToSign$),
            switchMap(([index, items]) =>
                items[index].document$.pipe(
                    mergeMap((envelop) =>
                        this.docusignService.signDocument$(items[index], envelop),
                    ),
                    tap((r) => {
                        this.toCheck.push(r.checkSignature$);
                        if (index < items.length - 1) {
                            this.indexToSign$.next(index + 1);
                        } else {
                            this.startCheck$.next(true);
                            this.step$.next(2);
                        }
                    }),
                ),
            ),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        this.allChecked$ = this.startCheck$.pipe(
            filter((v) => !!v),
            switchMap(() => combineLatest(this.toCheck)),
            switchMap(() =>
                this.documentService.updateSignedEntities$(
                    this.itemsToSign._,
                    this.forceLoadEntitiesAfterSigning,
                ),
            ),
            tap(() => {
                this.allItems.forEach((item) => {
                    this.signed[`${item.entityType}-${item.entityId}-${item.contextLabel || ''}`] =
                        true;
                });

                this.openAlert();
                this.close();
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    startSigning() {
        this.step$.next(1);
    }

    close() {
        this.modalService.dismiss({ signed: this.signed });
    }

    openAlert() {
        const headerKey = this.allItems.length > 1 ? 'crypto.docsSigned' : 'crypto.docSigned';
        const messageKey =
            this.allItems.length === 1 ? 'crypto.successDocSigned1' : 'crypto.successDocsSignedAll';

        const alert = this.alertService.create({
            icon: 'check',
            color: 'success',
            headerKey,
            messageKey,
            //messageParams,
            buttons: [
                {
                    titleKey: 'crypto.close',
                    handler: () => {
                        this.close();
                    },
                },
            ],
        });

        alert.onDidDismiss().then(() => {});
    }
}
