import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { mathFloatRound } from '@bazis/utils';

@Component({
    selector: 'bazis-stars',
    templateUrl: './stars.component.html',
    styleUrls: ['stars.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class StarsElement implements OnInit {
    @Input() ratingValue: number = 0;

    onlyFloat: number;

    firstFloatNumber: number;

    ngOnInit(): void {
        this.onlyFloat = this.ratingValue - Math.trunc(this.ratingValue);

        this.firstFloatNumber = Number(this.onlyFloat?.toString().slice(2, 3)) || 0;
    }

    hasHalf(npp) {
        // if (index === this.ratingValue || this.onlyFloat === 0 || this.firstFloatNumber < 5)
        //     return false;
        if (npp === Math.trunc(this.ratingValue) + 1 && this.firstFloatNumber > 4) return true;
        return false;
    }

    hasActive(npp) {
        if (
            (npp === Math.trunc(this.ratingValue) + 1 && this.firstFloatNumber > 4) ||
            npp <= this.ratingValue
        )
            return true;
        return false;
    }
}
