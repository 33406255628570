import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { EntData } from '@bazis/shared/models/srv.types';
import { BazisSignatureComponent } from '@bazis/document/component/signature.component';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { DocumentAccordeonComponent } from '@bazis/shared/components/document-accordeon/document-accordeon.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { CommonModule } from '@angular/common';
@Component({
    selector: 'bazis-signatures',
    templateUrl: './signatures.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        IconModule,
        DocumentAccordeonComponent,
        BazisSignatureComponent,
        ColorDirective,
        TranslocoModule,
        CommonModule,
    ],
})
export class BazisSignaturesComponent implements OnInit {
    @Input() signInfo: EntData;

    @Input() document: EntData;

    @Input() documentName: string;

    @Input() site: string = location.host;

    isScrollingContent: boolean = false;

    openDropdownMenu = false;

    constructor(public modalService: BazisModalService) {}

    ngOnInit() {
        console.log(this.signInfo);
    }

    onContentScroll(ev) {
        const scrollPosition = ev.target.scrollTop;
        this.isScrollingContent = scrollPosition > 50 ? true : false;
    }
}
