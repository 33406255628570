import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

export type BreadcrumbItem = {
    data?: any;
    name: string;
    url: string;
    params?: { [index: string]: any };
    queryParams?: { [index: string]: any };
    parents: BreadcrumbItem[];
    icon?: string;
    useOnlyIcon?: boolean;
};

const ROOT = { name: 'breadcrumbRootTitle', url: '/', parents: [] };

@Injectable({
    providedIn: 'root',
})
export class BazisBreadcrumbsService {
    breadcrumbs$: BehaviorSubject<BreadcrumbItem[]> = new BehaviorSubject([]);

    protected _savedParams = {};

    constructor() {}

    public createBreadcrumbs(
        route: ActivatedRoute,
        role = null,
        url: string = '',
        breadcrumbs: BreadcrumbItem[] = [ROOT],
    ) {
        const children: ActivatedRoute[] = route.children;
        if (children.length === 0) {
            this.breadcrumbs$.next(breadcrumbs);
            return;
        }

        children.forEach((child: ActivatedRoute, index) => {
            const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
            if (routeURL !== '') {
                url += `/${routeURL}`;
            }

            if (child.snapshot.data.breadcrumb) {
                if (breadcrumbs.length > 0 && url === breadcrumbs[breadcrumbs.length - 1].url) {
                    const currentLast = breadcrumbs[breadcrumbs.length - 1];
                    let parentsCount = (currentLast.parents ? currentLast.parents.length : 0) + 1;
                    breadcrumbs.splice(breadcrumbs.length - parentsCount, parentsCount);
                }

                breadcrumbs = this.generateBreadcrumbItem(
                    child,
                    role,
                    url,
                    breadcrumbs,
                    index === children.length - 1,
                    child.snapshot.data.breadcrumb.icon,
                    child.snapshot.data.breadcrumb.useOnlyIcon,
                );
            }

            return this.createBreadcrumbs(child, role, url, breadcrumbs);
        });
    }

    generateBreadcrumbItem(child, role, url, breadcrumbs, isLast, icon?, useOnlyIcon?) {
        const data =
            role && child.snapshot.data.roleBreadcrumb && child.snapshot.data.roleBreadcrumb[role]
                ? child.snapshot.data.roleBreadcrumb[role]
                : child.snapshot.data.breadcrumb;

        if (isLast && data.keepGetParams) this._savedParams[url] = child.snapshot.queryParams;

        let name = data.name;
        const params = child.snapshot.params;

        breadcrumbs = this.generateCustomParentBreadcrumbs(data, breadcrumbs, params);

        if (!name && data.nameTpl) {
            name = data.nameTpl.replace(/\${([A-Za-z0-9_#-]+)}/g, (_, v) => {
                const parts = v.split('#');
                if (parts[0] === 'param') {
                    return params[parts[1]];
                }
                return v;
            });
        }

        const breadcrumb = {
            ...data,
            name,
            url,
            params,
            queryParams: this._savedParams[url] || {},
            icon,
            useOnlyIcon,
        };

        breadcrumbs = this.addToBreadcrumbs(breadcrumbs, breadcrumb);

        return breadcrumbs;
    }

    addToBreadcrumbs(breadcrumbs, breadcrumb) {
        if (!breadcrumbs.length || breadcrumb.url !== breadcrumbs[breadcrumbs.length - 1].url)
            breadcrumbs.push(breadcrumb);
        else breadcrumbs[breadcrumbs.length - 1] = breadcrumb;

        return breadcrumbs;
    }

    generateCustomParentBreadcrumbs(data, breadcrumbs, params) {
        if (data.parents) {
            data.parents.forEach((parent) => {
                const url = parent.url
                    .split('/')
                    .map((part) => {
                        if (part[0] === ':' && params[part.substring(1)]) {
                            return params[part.substring(1)];
                        }
                        return part;
                    })
                    .join('/');
                const breadcrumb = {
                    ...parent,
                    url,
                    params,
                    queryParams: this._savedParams[url] || {},
                };
                breadcrumbs = this.addToBreadcrumbs(breadcrumbs, breadcrumb);
            });
        }
        return breadcrumbs;
    }
}
