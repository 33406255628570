<ng-container *transloco="let t">
    <div class="bazis-file-inline">
        <bazis-badge
            color="danger"
            size="medium"
        >
            {{ extention }}
        </bazis-badge>
        <span
            *ngIf="srcFile"
            class="bazis-list bazis-list_short-info"
        >
            <span class="bazis-item">
                <bazis-link
                    class="bazis-file-inline__file"
                    borderStyle="none"
                    [href]="hasLink ? srcFile : undefined"
                    [target]="hasLink ? '_blank' : undefined"
                    [title]="t(fileNameKey, fileNameParams) || nameFile"
                >
                    {{ t(fileNameKey, fileNameParams) || nameFile }}
                </bazis-link>
            </span>
            <span
                *ngIf="showFileSize"
                class="bazis-item"
            >
                <span>
                    {{ sizeFile | fileSize }}
                </span>
                <span
                    *ngIf="tooltipKey"
                    #tooltipTarget
                >
                    &nbsp;
                    <bazis-icon
                        name="interrogation"
                        size="xs"
                    ></bazis-icon>
                    <bazis-tooltip
                        [reference]="tooltipTarget"
                        placement="top-end"
                        [isStatic]="false"
                        [offset]="[8, 4]"
                        class="bh-popper-z-index"
                    >
                        {{ t(tooltipKey, tooltipParams) }}
                    </bazis-tooltip>
                </span>
            </span>
        </span>
    </div>
</ng-container>
