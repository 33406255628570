import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BazisLoaderService } from '@bazis/shared/services/loader.service';
import { CommonModule } from '@angular/common';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-global-loader',
    templateUrl: './global-loader.component.html',
    standalone: true,
    imports: [ColorDirective, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalLoaderComponent implements OnInit {
    @Input() centerX = 25;

    @Input() centerY = 25;

    @Input() radius = 14;

    constructor(public loaderService: BazisLoaderService) {}

    ngOnInit(): void {}
}
