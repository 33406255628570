import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TooltipModule } from '@bazis/shared/components/web/tooltip/tooltip.module';
import { ChipModule } from '@bazis/shared/components/web/chip/chip.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-status',
    template: `
        <div #reference>
            <bazis-chip
                class="bazis-status"
                [color]="color"
                [size]="size"
                [fill]="fill"
            >
                <span>{{ label }}</span>
            </bazis-chip>
            <bazis-tooltip
                [reference]="reference"
                [eventType]="'hover'"
                placement="top"
                [isStatic]="isStaticTooltip"
                class="bh-popper-z-index"
            >
                {{ label }}
            </bazis-tooltip>
        </div>
    `,
    standalone: true,
    imports: [TooltipModule, ChipModule, ColorDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
    @Input() label: string;

    @Input() color: string;

    @Input() size: 'small' | '' = '';

    @Input() hasDecor: boolean = false;

    @Input() fill?: 'clear' | 'outline' | 'solid';

    @Input()
    isStaticTooltip: boolean = false;

    @HostBinding('class') get chipStyle() {
        return {
            'bazis-status_nodecor': !this.hasDecor,
        };
    }

    constructor() {}
}
