import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { copyMessageFn } from '@bazis/utils';
import { BehaviorSubject } from 'rxjs';
import { TooltipModule } from '@bazis/shared/components/web/tooltip/tooltip.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { CommonModule } from '@angular/common';
import { LocalNumberPipe } from '@bazis/shared/pipes/local-number.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-coordinates',
    templateUrl: './coordinates.component.html',
    standalone: true,
    imports: [
        TooltipModule,
        IconModule,
        CommonModule,
        LocalNumberPipe,
        TranslocoModule,
        ColorDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordinatesComponent implements OnDestroy, OnInit {
    @Input()
    point: {
        type: 'Point';
        coordinates: [number, number]; // [lng, lat]
    };

    tooltipKey$ = new BehaviorSubject('tooltip.copyCoordinate');

    tooltipParams: any = null;

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    copy(e) {
        // широта, долгота
        copyMessageFn(`${this.point.coordinates[1]}, ${this.point.coordinates[0]}`);

        this.tooltipKey$.next('tooltip.copied');
        e.preventDefault();
        e.stopImmediatePropagation();

        setTimeout(() => {
            this.tooltipKey$.next('tooltip.copyCoordinate');
        }, 5000);
    }
}
