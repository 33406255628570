import { ViewEncapsulation, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'bazis-link',
    templateUrl: './link.component.html',
    styleUrls: ['link.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class LinkElement {
    @Input() borderStyle: 'dotted' | 'dashed' | 'solid' | 'none' = 'dashed';

    @Input() size: 'l' | 'm' | 's' | 'xs' | '' = '';

    @Input() href?: string;

    // TODO:На самом деле, если это собрать в web-компонент, то использовать ангуляровский роутинг нельзя!!!
    // Пересмотри компонент
    @Input() routerLink?: string;

    @Input() bindRouterLink?: string;

    @Input() fragment?: string;

    @Input() download?: string = null;

    @Input() target?: '_blank' | '_self' | '_parent' | '_top';

    @Input() disabled: boolean = false;

    @Input() fontWeight?: '400' | '500' = '500';

    @HostBinding('class') get linkStyle() {
        return {
            [`bazis-link_${this.size}`]: !!this.size,
            [`bazis-link_${this.borderStyle}`]: !!this.borderStyle,
            ['bazis-link_normal']: this.fontWeight === '400',
        };
    }

    constructor() {}
}
