import {
    ViewEncapsulation,
    Component,
    Input,
    ViewChild,
    ElementRef,
    HostListener,
    Renderer2,
    HostBinding,
    Self,
    OnInit,
    AfterViewInit,
    Output,
    EventEmitter,
    TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
    selector: 'bazis-range',
    templateUrl: './range.component.html',
    styleUrls: ['range.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class RangeElement implements OnInit, AfterViewInit {
    @ViewChild('range') rangeEl: ElementRef;

    // Minimum integer value of the range.
    @Input() min: number = 0;

    // Maximum integer value of the range.
    @Input() max: number = 100;

    @Input() step: number = 1;

    @Input() disabled: boolean = false;

    @Input() pin: boolean = true;

    @Input() pinDimension: string;

    @Input() customPinTemplate: TemplateRef<any>;

    @Input() value: number | string;

    @Input() showScale = false;

    @Output() valueChanged: EventEmitter<number | string> = new EventEmitter();

    get scale() {
        const array = [];
        for (let i = this.min; i <= this.max; i = i + this.step) {
            array.push(i);
        }
        return array;
    }

    @HostListener('input', ['$event']) public handleInput(): void {
        this.setStyle(this.rangeEl.nativeElement.value);
        // this.onChange(this.rangeEl.nativeElement.value);
    }

    @HostBinding('style')
    style = {};

    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {
        this.disabled = this.disabled !== undefined && this.disabled !== false;
        // this.checked = this.checked !== undefined && this.checked !== false;
    }

    ngAfterViewInit(): void {
        this.setStyle(this.rangeEl.nativeElement.value);
    }

    setStyle(value) {
        this.style = {
            '--value': value,
            '--min': this.min,
            '--max': this.max,
        };
    }
}
