import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-simple-context-menu',
    templateUrl: './simple-context-menu.component.html',
    standalone: true,
    imports: [CommonModule, TranslocoModule, ColorDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleContextMenuComponent {
    @Input() menu: [
        {
            titleKey: string;
            titleParams?: { [index: string]: any };
            clickFn: () => void;
        },
    ];
}
