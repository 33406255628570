import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { EntDescription } from '@bazis/shared/models/srv.types';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { EntityPipe } from '@bazis/shared/pipes/entity.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-gallery-modal',
    templateUrl: './gallery-modal.component.html',
    standalone: true,
    imports: [CommonModule, ButtonModule, IconModule, EntityPipe, ColorDirective, TranslocoModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryModalComponent implements OnDestroy, OnInit {
    @Input()
    gallery: EntDescription[];

    //  Если необходимо задать отличный от имени файла alt картинкам
    @Input() imagesAlt?: string[];

    @Input()
    startIndex: number = 0;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.code === 'ArrowLeft' && this.currentIndex > 0) {
            this.next(-1);
        } else if (event.code === 'ArrowRight' && this.currentIndex < this.gallery.length - 1) {
            this.next(1);
        }
    }

    currentIndex: number;

    constructor(protected modalService: BazisModalService) {}

    ngOnInit(): void {
        this.currentIndex = this.startIndex;
    }

    ngOnDestroy(): void {}

    next(step = 1) {
        let newIndex = this.currentIndex + step;

        if (newIndex > this.gallery.length - 1) {
            newIndex = 0;
        } else if (newIndex < 0) {
            newIndex = this.gallery.length - 1;
        }

        this.currentIndex = newIndex;
    }

    closeModal() {
        this.modalService.dismiss();
    }
}
