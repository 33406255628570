import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { GalleryModalComponent } from '@bazis/shared/components/modals/gallery-modal.component';
import { EntData } from '@bazis/shared/models/srv.types';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { EntityPipe } from '@bazis/shared/pipes/entity.pipe';
import { GallerySkeletonComponent } from '@bazis/shared/components/gallery/gallery-skeleton.component';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-gallery',
    templateUrl: './gallery.component.html',
    standalone: true,
    imports: [
        ColorDirective,
        CommonModule,
        ButtonModule,
        IconModule,
        EntityPipe,
        GallerySkeletonComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent implements OnDestroy, OnInit {
    @Input() imageEntities: EntData[];

    //  Если необходимо задать отличный от имени файла alt картинкам
    @Input() imagesAlt?: string[];

    @Input() isPreview: boolean;

    @Input() size?: 'mini' | 'small' | 'large';

    constructor(private modalService: BazisModalService) {}

    ngOnInit(): void {
        this.isPreview = this.isPreview !== undefined && this.isPreview !== false;
    }

    ngOnDestroy(): void {}

    openGallery(gallery, index) {
        const modal = this.modalService.create({
            component: GalleryModalComponent,
            componentProperties: {
                gallery,
                startIndex: index,
                imagesAlt: this.imagesAlt,
            },
            modalType: 'fullscreen gallery',
            hasCloseIcon: false,
        });
    }
}
