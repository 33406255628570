import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StickyDirective } from '@bazis/shared/directives/sticky.directive';
import { SkeletonModule } from '@bazis/shared/components/web/skeleton/skeleton.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'bazis-entity-status-list-skeleton',
    templateUrl: './entity-status-list-skeleton.component.html',
    standalone: true,
    imports: [ColorDirective, SkeletonModule, StickyDirective, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityStatusListSkeletonComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
