<div
    class="bazis-collapsible-to-more"
    *ngIf="{
        isShakeTabs: isShakeTabs.$ | async,
        observeElementResizing: observeElementResizing$ | async
    } as displayData"
    [class.bazis-collapsible-to-more--opacity]="displayData.isShakeTabs"
    [class.bazis-collapsible-to-more--notabs]="!tabList || !tabList.length"
    #wrapper
>
    <div
        *ngIf="displayData.isShakeTabs"
        class="bazis-collapsible-to-more__shake"
    >
        <bazis-skeleton></bazis-skeleton>
    </div>
    <div
        part="start"
        class="bazis-collapsible-to-more__start"
    >
        <bazis-tabs
            #bazisTabs
            [tabList]="tabList"
            [tabTemplate]="tabTemplate"
            [selectedTab]="selectedTab.$ | async"
            [hideByZeroCount]="hideByZeroCount"
            [hasMore]="hasMore$ | async"
            [moreInsideItemsCounter]="moreInsideItemsCounter"
            adaptiveMode="useMore"
            (selectedTabChange)="changeSelectedTab($event)"
        ></bazis-tabs>

        <ng-content select="start"></ng-content>
    </div>
    <div
        part="end"
        class="bazis-collapsible-to-more__end"
        #endPart
    >
        <ng-content select="end"></ng-content>
    </div>
</div>
