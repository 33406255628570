import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'transit',
    standalone: true,
})
export class TransitPipe implements PipeTransform {
    constructor(private entity: BazisEntityService) {}

    transform(id: string) {
        if (!id) return of(null);

        return this.entity
            .getEntity$('statusy.transit', encodeURIComponent(id))
            .pipe(map((v) => v?.$snapshot));
    }
}
