import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntDescription } from '@bazis/shared/models/srv.types';
import { CommonModule } from '@angular/common';
import { EntityPipe } from '@bazis/shared/pipes/entity.pipe';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-gallery-single-slider',
    templateUrl: './gallery-single-slider.component.html',
    standalone: true,
    imports: [CommonModule, EntityPipe, IconModule, ColorDirective, ButtonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySingleSliderComponent {
    @Input()
    images: EntDescription[];

    //  Если необходимо задать отличный от имени файла alt картинкам
    @Input() imagesAlt?: string[];

    @Input() iconPrev = 'angle-small-left';

    @Input() iconNext = 'angle-small-right';

    @Input() fillControl: 'solid' | 'outline' | 'clear' = 'outline';

    @Input() sizeControl: 'xs' | 'small' | 'default' | 'large' = 'default';

    currentIndex = 0;

    constructor() {}
}
