import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BazisModalService } from '@bazis/shared/services/modal.service';

@Component({
    selector: 'bazis-transit-modal',
    templateUrl: './transit-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisTransitModalComponent implements OnDestroy, OnInit {
    @Input()
    payload: { [index: string]: any };

    @Input()
    hint;

    @Input()
    transit;

    form = new FormGroup({});

    controlNames = [];

    controlRequirements: { [index: string]: BehaviorSubject<boolean> } = {};

    parents = {};

    dysplayPayloadMap = {};

    constructor(public modalService: BazisModalService) {}

    ngOnInit(): void {
        const addControlName = (source, parentName = '') => {
            const controlNames = source ? Object.keys(source).sort() : [];
            controlNames.forEach((controlName) => {
                if (parentName) this.parents[controlName] = parentName;

                if (!source[controlName].component) {
                    addControlName(source[controlName], controlName);
                    return;
                }
                if (
                    controlName === 'static_id' &&
                    (!source[controlName].optionList || source[controlName].optionList.length === 0)
                )
                    return;
                if (controlName === 'dynamic' && this.payload.static_id?.optionList.length > 0) {
                    return;
                }
                this.controlNames.push(controlName);

                const isRequired = source[controlName].required;

                this.controlRequirements[controlName] = new BehaviorSubject(isRequired);
                const initialValue = source[controlName].type === 'string' ? '' : [];
                this.dysplayPayloadMap[controlName] = source[controlName];
                this.form.addControl(
                    controlName,
                    new FormControl(initialValue, isRequired ? [Validators.required] : []),
                );
            });
        };
        addControlName(this.payload);
    }

    ngOnDestroy(): void {}

    cancel(action = null) {
        this.modalService.dismiss(null);
    }

    send() {
        const formResult = this.form.value;
        const result = {};

        const addValue = (controlName, target) => {
            if (this.dysplayPayloadMap[controlName].component === 'file') {
                // преобразуем файлы в ожидаемый беком формат {id: file}
                if (this.dysplayPayloadMap[controlName].maxItems === 1) {
                    target[controlName] = [formResult[controlName]];
                }
                target[controlName] = formResult[controlName].map((file) => {
                    return {
                        id: file,
                    };
                });
            } else {
                target[controlName] = formResult[controlName];
            }
        };

        this.controlNames.forEach((controlName) => {
            if (this.parents[controlName]) {
                if (!result[this.parents[controlName]]) {
                    result[this.parents[controlName]] = {};
                }
                addValue(controlName, result[this.parents[controlName]]);
            } else {
                addValue(controlName, result);
            }
        });
        // Даже если payload отсутствовал, отправится {}, что будет ок для продолжения транзита.
        // При закрытии окна уйдет null
        this.modalService.dismiss(result);
    }
}
