import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-default-loader',
    templateUrl: './default-loader.component.html',
    standalone: true,
    imports: [ColorDirective, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultLoaderComponent implements OnInit {
    @Input() message: string = '';

    @Input() theme: 'light' | 'dark' | 'transparent' = 'light';

    @Input() centerX = 25;

    @Input() centerY = 25;

    @Input() radius = 14;

    @Input() type: 'inline' | 'block' = 'inline';

    @Input() cssClass: string = '';

    constructor() {}

    ngOnInit(): void {}
}
