import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { BazisBreadcrumbsService } from '@bazis/shared/services/breadcrumbs.service';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { CommonModule } from '@angular/common';
import { OrganizationPipe } from '@bazis/shared/pipes/organization.pipe';
import { RouterLink } from '@angular/router';
import { EntityPipe } from '@bazis/shared/pipes/entity.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    standalone: true,
    imports: [
        IconModule,
        ButtonModule,
        CommonModule,
        OrganizationPipe,
        RouterLink,
        EntityPipe,
        TranslocoModule,
        ColorDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnDestroy, OnInit {
    // TODO: (пометка для себя)
    // Идеи из https://ionicframework.com/docs/api/breadcrumbs
    // посмотреть вариант доработки до web- компонента

    @Input() iconSeparator: string;

    @Input() doNotDisplayLength: number = 1;

    list$ = this.breadcrumbs.breadcrumbs$;

    constructor(
        public breadcrumbs: BazisBreadcrumbsService,
        public mqSrv: BazisMediaQueryService,
        private configurationService: BazisConfigurationService,
    ) {
        if (!this.iconSeparator) {
            this.iconSeparator = this.configurationService.breadcrumbsIconSeparator;
        }
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
