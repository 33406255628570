import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
    selector: '[color]',
    standalone: true,
})
export class ColorDirective {
    constructor() {}

    @Input() color: string;

    @HostBinding('class') get getColorClass() {
        return this.color
            ? {
                  'bazis-color': true,
                  [`bazis-color-${this.color}`]: true,
              }
            : null;
    }
}
