import { Pipe, PipeTransform } from '@angular/core';
import { SNILS_MASK_SETTINGS } from '@bazis/configuration.service';
import { NgxMaskService } from 'ngx-mask';

@Pipe({
    name: 'snils',
    standalone: true,
})
export class SnilsPipe implements PipeTransform {
    constructor(private maskService: NgxMaskService) {}

    transform(value: string, pattern = SNILS_MASK_SETTINGS?.mask): string {
        if (!pattern || !value) return value;
        return this.maskService.applyMask(value, pattern);
    }
}
