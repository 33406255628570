import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
    selector: '[bg]',
    standalone: true,
})
export class BackgroundDirective {
    constructor() {}

    @Input() bg: string;

    @HostBinding('class') get getColorClass() {
        return this.bg
            ? {
                  'bh-bg': true,
                  [`bazis-bg-${this.bg}`]: true,
              }
            : null;
    }
}
